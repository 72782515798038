/* eslint-disable complexity */
import React, { useEffect, useMemo, useState } from 'react'
import { useGetQuotes } from 'hooks/api'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Skeleton } from 'antd'
import _ from 'lodash'
import { setDestinationCurrencyAmount, setExchangeRateAmount, setSelectedQuotes } from 'store/slices/wizard'
// eslint-disable-next-line no-unused-vars
import DrawerQuotes from './drawerQuotes'
import DrawerQuotesNew from './drawerQuotesNew'
import { walletTagCurrencies } from 'utils/constants'
import mixPanel from '../services/mixpanel'
import filterQuotes from '../services/filterQuotes'

const Container = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  // background-color: ${({ theme }) => theme.colors.quoteCardBackground};
`

const QuoteContainer = styled.div`
  height: calc(100% - 53px);
  overflow-y: auto;
`
const ErrorSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const ErrorMessage = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.errorText};
  width: 95%;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SourceSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    display: flex;
    align-items: center;
  }
  .ant-skeleton-title {
    margin-top: 13px !important;
    width: 100% !important;
  }
`

const InputWrapper = styled.div`
  margin-top: 0px;
`

const QuotesNew = ({ enableSPDetails, serviceProviderDetails, onOpen, onClose, isInitialLoad, sourceErrorMsg, topServiceProviderList, onModalError, isWalletAddressEntered, resetWalletAddressEntered, isCountryChange }) => {
  const dispatch = useDispatch()
  const refreshDuration = 60 * 1000
  let refetchInterval = refreshDuration
  let timeout
  let refetchEveryMinuteUpToFiveMinutes = refreshDuration
  const { buySellFlow, countryCode, sourceAmount, sourceCurrencyCode, destinationCurrencyCode, paymentMethod, isSourceAmountError, isWalletAddressError, walletTag, walletAddress } = useSelector((state) => state.wizard)

  const [invalidWalletAddressErrorMessage, setInvalidWalletAddressErrorMessage] = useState('')
  const [initialLoading, setInitialLoading] = useState(true)
  const [ lastActiveFlow, setLastActiveFlow ] = useState(buySellFlow)
  const [ invalidQuote, setInvalidQuote ] = useState(false)
  const [ showQuotes, setShowQuotes ] = useState(false)
  const [ selectedQuote, setSelectedQuote ] = useState([])
  const [ quoteList, setQuoteList ] = useState([])
  let quoteEnabled = false

  if (buySellFlow === 'BUY' && countryCode && sourceAmount > 0 && sourceCurrencyCode && destinationCurrencyCode && paymentMethod?.paymentMethod && !isSourceAmountError) {
    if (walletTagCurrencies?.includes(sourceCurrencyCode) || walletTagCurrencies?.includes(destinationCurrencyCode) && walletTag) {
      quoteEnabled = enableSPDetails && true
    }
    if (!(walletTagCurrencies?.includes(sourceCurrencyCode) || walletTagCurrencies?.includes(destinationCurrencyCode))) {
      quoteEnabled = enableSPDetails && true
    }
  } else if (buySellFlow === 'SELL' && countryCode && sourceAmount > 0 && sourceCurrencyCode && destinationCurrencyCode && !isSourceAmountError) {
    if (walletTagCurrencies?.includes(sourceCurrencyCode) || walletTagCurrencies?.includes(destinationCurrencyCode) && walletTag) {
      quoteEnabled = enableSPDetails && true
    }
    if (!(walletTagCurrencies?.includes(sourceCurrencyCode) || walletTagCurrencies?.includes(destinationCurrencyCode))) {
      quoteEnabled = enableSPDetails && true
    }
  } else {
    quoteEnabled = enableSPDetails && false
  }

  if (buySellFlow === 'BUY' && quoteEnabled && isWalletAddressEntered) {
    if (walletAddress === '' || walletAddress !== '') {
      quoteEnabled = false
    }
    if (initialLoading) {
      quoteEnabled = true
    }
  }

  if (isWalletAddressEntered) {
    timeout = setTimeout(() => {
      resetWalletAddressEntered(false)
      clearTimeout(timeout)
    }, refreshDuration)
  }

  useEffect(() => {
    if (!isInitialLoad && buySellFlow === 'BUY' && !invalidWalletAddressErrorMessage && !invalidQuote) {
      setShowQuotes(false)
    }
    if (!isInitialLoad && buySellFlow === 'SELL' && !invalidWalletAddressErrorMessage && !invalidQuote) {
      setShowQuotes(false)
    }
  }, [countryCode, sourceAmount, sourceCurrencyCode, destinationCurrencyCode, paymentMethod, isSourceAmountError, walletTag, isInitialLoad])

  useEffect(() => {
    if (lastActiveFlow !== buySellFlow) {
      setInitialLoading(true)
      setLastActiveFlow(buySellFlow)
      setShowQuotes(false)
    }
  }, [buySellFlow])

  useEffect(() => {
    if (isCountryChange && invalidWalletAddressErrorMessage) {
      setInvalidWalletAddressErrorMessage('')
      setInvalidQuote(true)
    }
  }, [isCountryChange])

  const { data, isFetching } = useGetQuotes({
    countryCode,
    sourceCurrencyCode,
    destinationCurrencyCode,
    sourceAmount: +sourceAmount,
    walletAddress: isWalletAddressError ? '' : walletAddress,
    paymentMethodType: paymentMethod.paymentMethod,
  }, quoteEnabled, refetchEveryMinuteUpToFiveMinutes)

  refetchEveryMinuteUpToFiveMinutes = useMemo(() => {
    setTimeout(() => {
      refetchInterval = false
    }, 10 * refreshDuration)

    if (!refetchInterval) {
      document.getElementById('meld-wizard').addEventListener('click', () => {
        refetchInterval = refreshDuration
        history.pushState({
          countryCode,
          sourceAmount,
          sourceCurrencyCode,
          destinationCurrencyCode,
          walletAddress,
          paymentMethodType: paymentMethod.subtype
        }, 'Wizard')
        window.location.reload()
      })
    }
    return refetchInterval
  }, [refetchInterval])

  useEffect(() => {
    if (!isFetching && data?.response?.status >= 400) {
      setInitialLoading(false)
      if (data?.response?.status >= 400) {
        onModalError(true)
      }

      let message = 'No quotes available, try another combination'
      if (data?.response?.data?.message?.toLowerCase() === 'token has expired') {
        message = 'Please refresh the page to continue seeing quotes'
      }
      setInvalidWalletAddressErrorMessage(message)
      setShowQuotes(true)
      setInvalidQuote(true)
      setSelectedQuote([])
      setQuoteList([])
      dispatch(setSelectedQuotes([]))
      dispatch(setDestinationCurrencyAmount(0))
      dispatch(setExchangeRateAmount(0))
    }
    if (!isFetching && data?.quotes) {
      setInitialLoading(false)
      setQuoteList(data)
      setInvalidQuote(false)
      const displayQuote = reorderByTopServiceProviders(data?.quotes?.filter((quote) => quote.destinationAmount > 0), topServiceProviderList).slice(0, 1)
      // data?.quotes?.filter((quote) => quote.destinationAmount > 0).sort(sortQuotes).slice(0, 1)
      if (displayQuote.length > 0 && displayQuote[0].serviceProvider) {
        setInvalidQuote(false)
        selectedQuotation(displayQuote)
      } else {
        mapError()
        setShowQuotes(true)
        setInvalidQuote(true)
        setSelectedQuote([])
        dispatch(setSelectedQuotes([]))
        dispatch(setDestinationCurrencyAmount(0))
        dispatch(setExchangeRateAmount(0))
      }
    }
    if (isFetching) {
      // setInitialQuote(false)
      dispatch(setDestinationCurrencyAmount(0))
      dispatch(setExchangeRateAmount(0))
    }
  }, [data, isFetching])

  useEffect(() => {
    if (sourceErrorMsg !== '') {
      setInvalidWalletAddressErrorMessage(sourceErrorMsg)
      setShowQuotes(true)
      setInvalidQuote(true)
      setSelectedQuote([])
      dispatch(setSelectedQuotes([]))
      dispatch(setDestinationCurrencyAmount(0))
      dispatch(setExchangeRateAmount(0))
    }
  }, [sourceErrorMsg])

  const reorderByTopServiceProviders = (quotes, topServiceProviders) => {
    if (!topServiceProviders || topServiceProviders.length === 0) {
      return quotes.slice().sort(sortQuotes) // Return sorted quotes if topServiceProviders is null or empty
    }

    const topProvidersUpperCase = topServiceProviders
    const sortedQuotes = quotes?.slice().sort(sortQuotes)

    return filterQuotes(sortedQuotes, topProvidersUpperCase)
  }

  const selectedQuotation = (displayQuote) => {
    setShowQuotes(true)
    setSelectedQuote(displayQuote)
    dispatch(setSelectedQuotes(displayQuote))
    dispatch(setDestinationCurrencyAmount(displayQuote[0].destinationAmount))
    dispatch(setExchangeRateAmount(displayQuote[0].exchangeRate))
  }

  const isError = isFetching && (data?.response?.status >= 400 || data?.response?.data?.error || data?.response?.data?.message || _.every(data?.quotes, (quote) => quote.destinationAmount <= 0))
  const mapError = () => {
    const message = `Quote not available for selected combinaton`
    setInvalidWalletAddressErrorMessage(message)
  }

  const sortQuotes = (a,b) => {
    return b.destinationAmount - a.destinationAmount
  }

  useEffect(()=>{
    if (!isFetching && !!isError) {
      mapError()
      setInvalidQuote(true)
    }
  },[isError, isFetching])

  return (
    <>
      <Container>
        <QuoteContainer>
          <SourceSkeleton active loading={isFetching || (!showQuotes)} paragraph={{rows: 0}} style={{ marginBottom: '5px', marginTop: '2px' }}>
            { invalidQuote &&
              <ErrorSection>
                <ErrorMessage>
                  {invalidWalletAddressErrorMessage}
                </ErrorMessage>
              </ErrorSection>
            }
            {
              selectedQuote?.length > 0 && !invalidQuote &&
              <InputWrapper>
                <DrawerQuotesNew
                  selectedQuote={selectedQuote}
                  serviceProviderDetails={serviceProviderDetails}
                  buySellFlow={buySellFlow}
                  data={quoteList}
                  header={''}
                  onSelect={(e) => {
                    setSelectedQuote(e)
                    selectedQuotation(e)
                    mixPanel({
                      eventName: buySellFlow === 'BUY' ? 'buy_details_quote_selection' : 'sell_details_quote_selection',
                      buySellFlow: buySellFlow,
                      serviceProvider: e?.[0]?.serviceProvider,
                      payload: {
                        countryCode: countryCode,
                        amount: sourceAmount,
                        paymentMethodType: paymentMethod.subtype,
                        sourceCurrencyCode: sourceCurrencyCode,
                        destinationCurrencyCode: destinationCurrencyCode
                      }
                    })
                  }}
                  onOpen={(e) => {
                    onOpen(e)
                    mixPanel({
                      eventName: buySellFlow === 'BUY' ? 'buy_details_quote_drawer_open' : 'sell_details_quote_drawer_open',
                      buySellFlow: buySellFlow
                    })
                  }}
                  onClose={(e) => {
                    onClose(e)
                    mixPanel({
                      eventName: buySellFlow === 'BUY' ? 'buy_details_quote_drawer_back_button' : 'sell_details_quote_drawer_back_button',
                      buySellFlow: buySellFlow
                    })
                  }}
                />
              </InputWrapper>
            }
          </SourceSkeleton>
        </QuoteContainer>
      </Container>
    </>
  )
}

export default QuotesNew
