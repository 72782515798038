/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useGetWidgetToken, useGetServiceProviderBuyCountry, useGetAccountCryptoLimitDetails, useGetPaymentMethodsByCountryCode, useFiatCurrencyByCountryCode, useCryptoCurrencyByCountryCode, useGetServiceProviderInfo, useGetAccountPreferencesDetails } from 'hooks/api'

import mixpanel from 'mixpanel-browser'
import WizardPageWrapper from './wizardPageWrapper'
import { setAccountId, setDisplayPage, setToken } from 'store/slices/app'
import jwtDecode from 'jwt-decode'
import { setCountryCode, setCurrentStep, setErrorDetails, setWizardDrawerRef, setAccountPublicPreference, setAccountOnRampServiceProviders, setAccountOffRampServiceProviders, setAccountCryptoLimit, setAccountCountry, setAccountPaymentMethod, setAccountFiatCurrency, setAccountCryptoCurrency, setCountryCodeLocked } from 'store/slices/wizard'
import countryCodeByTimezone from 'utils/countryCodeByTimezone'
import _ from 'lodash'
import Button from 'components/ui/button'
import { initAccountLogo, updateCommonTheme } from 'store/slices/theme'
import { setAccountLogo } from 'store/slices/account'
import { LoadingBlock } from 'components/ui/loading-overlay'
import filterCountries from './services/filterCountries'
import WizardSourceWrapper from './wizardSourceWrapper'
import WizardPaymentMethodWrapper from './wizardPaymentMethodWrapper'
import WizardWalletWrapper from './wizardWalletWrapper'
import WizardDestinationWrapper from './wizardDestinationWrapper'
import WizardRedirect from './wizardRedirect'
import mixPanel from './services/mixpanel'
import WizardContinueWrapper from './wizardContinue'
import { getURLParams } from 'utils/data-model'
import { setTopServiceProviders } from 'store/slices/serviceProvider'
import { Modal } from 'antd'
import Icon from '@ant-design/icons'
import { ReactComponent as WarningIcon } from './components/icons/warning-icon.svg'
import currencyByCountry from 'assets/currency-by-country.json'

const ErrorModal = styled(Modal)`
  max-width: 420px !important;
  top: 250px !important;

  .ant-modal-mask {
    background-color: #181e2be3 !important;
  }

  .ant-modal-content {
    background-color: ${({ theme }) => theme.colors.modalBackground} !important;
    border-radius: 10px !important;
  }
  .ant-modal-close {
    display: none !important;
  }
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`

const ModalTitle = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.modalHeaderText} !important;
  margin-bottom: 5px;
  margin-top: 10px;
`

const ModalBody = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.modalBodyText} !important;
`

const ContinueButton = styled(Button)`
  width: 100%;
  font-size: 16px;
  margin-top: 20px;
  height: 45px;

  span {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.ant-btn-primary {
    :disabled {
      color: ${({ theme }) => theme.colors.buttonDisabledColor} !important;
      background: ${({ theme }) => theme.colors.buttonDisabled} !important;
    }
    border: 1px solid ${({ theme }) => theme.colors.modalButtonBorder} !important;
    color: ${({ theme }) => theme.colors.modalButtonTextColor} !important;
    background: ${({ theme }) => theme.colors.modalButtonBackgroundColor} !important;
    margin-top: 10px !important;
  }
`

const WarningImg = styled(Icon)`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.modalHeaderText};
  height: 40px;
  width: 40px;
  border: 1px solid ${({ theme }) => theme.colors.modalIcon};
  border-radius: 10px;
  padding: 5px;
`

const Wizard = () => {
  const {
    publicKey,
    urlParams,
    currentStep,
    buySellFlow,
    countryCode,
    accountPublicPreference,
    accountOnRampServiceProviders,
    accountOffRampServiceProviders,
    accountCryptoLimit,
    accountCountry,
    accountPaymentMethod,
    accountFiatCurrency,
    accountCryptoCurrency,
    isRedirect,
    redirectLockCountry,
  } = useSelector((state) => state.wizard)
  if (process.env.REACT_APP_ENVIRONMENT?.toUpperCase() === 'PRODUCTION' && process.env.REACT_APP_MIXPANEL_KEY_PRODUCTION) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY_PRODUCTION,
      { debug: true, track_pageview: true, persistence: 'localStorage' }
    )
    mixPanel({
      eventName: buySellFlow === 'BUY' ? 'buy_wizard_page_view' : 'sell_wizard_page_view',
      buySellFlow: buySellFlow
    })
  }
  const theme = useTheme()
  const qparams = getURLParams()
  const wizardDrawerRef = useRef(null)
  const dispatch = useDispatch()
  const [ isDataFetch, setIsDataFetch ] = useState(false)
  const [ isInitialLoad, setIsInitialLoad ] = useState(true)
  const [ isSourceAmountValid, setIsSourceAmountValid ] = useState(true)
  const [ isWalletAddressEntered, setIsWalletAddressEntered ] = useState(false)
  const [ transactionFlow, setTransactionFlow ] = useState(buySellFlow)
  const [ topServiceProviderList, setTopServiceProviderList ] = useState([])
  const [ serviceProviderDetails, setServiceProviderDetails ] = useState({
    isApiDataFetch: false,
    defaultCountryCode: urlParams?.countryCodeLocked || urlParams?.countryCode || countryCodeByTimezone || 'US',
    countries: {
      BUY: [],
      SELL: []
    },
    paymentMethod: [],
    cryptoLimit: [],
    fiatCurrency: [],
    cryptoCurrency: [],
    serviceProvider: {
      BUY: [],
      SELL: []
    },
  })
  let [ retryAttemp, setRetryAttemp ] = useState(0)
  const [ countrySelected, setCountrySelected ] = useState(false)
  let [ userSelectedCountry, setUserSelectedCountry ] = useState('')
  const [ sourceErrorMsg, setSourceErrorMsg ] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [ modalTitle, setModalTitle ] = useState('')
  const [ modalBody, setModalBody ] = useState('')
  const [ showWarningIcon, setShowWarningIcon ] = useState(true)
  const [ isCountryChange, setIsCountryChange ] = useState(false)
  const [ lastPaymentMethod, setLastPaymentMethod ] = useState({})
  const [ lastCryptoCurrency, setLastCryptoCurrency ] = useState({})
  const [ userWalletAddress, setUserWalletAddress ] = useState('')

  const { data: widgetTokenResult, isFetched: hasFetchedWidgetToken } = useGetWidgetToken(publicKey)
  const { mutateAsync: fetchAccountPreferences } = useGetAccountPreferencesDetails()
  const { mutateAsync: fetchServiceProviderBuyCountry } = useGetServiceProviderBuyCountry()
  const { mutateAsync: fetchServiceProviderCryptoLimit } = useGetAccountCryptoLimitDetails()
  const { mutateAsync: fetchPaymentMethod } = useGetPaymentMethodsByCountryCode()
  const { mutateAsync: fetchFiatCurrency } = useFiatCurrencyByCountryCode()
  const { mutateAsync: fetchCryptoCurrency } = useCryptoCurrencyByCountryCode()
  const { mutateAsync: fetchServiceProviderDetails } = useGetServiceProviderInfo()

  // Calculate the country and currency options based on what is available per-provider, per-flow

  useEffect(async () => {
    if (qparams.token) {
      return dispatch(setDisplayPage('landing'))
    }
    if (widgetTokenResult?.response?.status === 401) {
      dispatch(setErrorDetails({ message: 'Invalid url. Check your public key and try again.' }))
      return dispatch(setCurrentStep('wizardError'))
    }
    if (hasFetchedWidgetToken && widgetTokenResult?.token) {
      dispatch(setToken(widgetTokenResult?.token))

      try {
        dispatch(setAccountId(jwtDecode(widgetTokenResult?.token)?.accountId))
        fetchPreferences(publicKey, jwtDecode(widgetTokenResult?.token)?.accountId)
        setIsDataFetch(true)
      } catch (e) {
        // do nothing with the error; we don't care
      }
      dispatch(setWizardDrawerRef(wizardDrawerRef.current))
    }
  }, [widgetTokenResult, hasFetchedWidgetToken])

  const fetchPreferences = async (publicKey, accountId, fetchData = false) => {
    if (qparams.token) {
      return
    }
    const [serviceProviderBuyList, serviceProviderSellList, accountPreference] = await Promise.all([
      fetchServiceProviderDetails({categories: 'CRYPTO_ONRAMP', fetchData: fetchData, serviceProvider: serviceProviderDetails.serviceProvider.BUY, accountServiceProvider: accountOnRampServiceProviders}),
      fetchServiceProviderDetails({categories: 'CRYPTO_OFFRAMP', fetchData: fetchData, serviceProvider: serviceProviderDetails.serviceProvider.SELL, accountServiceProvider: accountOffRampServiceProviders}),
      fetchAccountPreferences({publicKey: publicKey, accountId: accountId, fetchData: fetchData, accountPublicPreference: accountPublicPreference})
    ])
    if (_.isEmpty(accountPublicPreference)) {
      dispatch(setAccountPublicPreference(accountPreference))
    }
    if (_.isEmpty(accountOnRampServiceProviders)) {
      dispatch(setAccountOnRampServiceProviders(serviceProviderBuyList))
    }
    if (_.isEmpty(accountOffRampServiceProviders)) {
      dispatch(setAccountOffRampServiceProviders(serviceProviderSellList))
    }
    serviceProviderDetails.serviceProvider.BUY = serviceProviderBuyList
    serviceProviderDetails.serviceProvider.SELL = serviceProviderSellList
    setServiceProviderDetails({...serviceProviderDetails})

    const updatedPreference = _.find(accountPreference?.preferences, (pref) => pref.type === 'CRYPTO')?.preference
    if (!_.isEmpty(updatedPreference)) {
      const preferences = {...updatedPreference}
      document.body.classList.add('transition')
      preferences.accountLogoUrl = urlParams?.accountLogoUrl || preferences.accountLogoUrl || initAccountLogo
      dispatch(setAccountLogo(preferences.accountLogoUrl))
      setTopServiceProviderList(preferences.topServiceProviders || [])
      dispatch(setTopServiceProviders(preferences.topServiceProviders))
      dispatch(updateCommonTheme({
        ..._.pick(preferences, ['accountLogoUrl', 'offrampEnabled', 'onrampEnabled', 'returnUrl', 'buttonColor', 'darkModeButtonColor']),
      }))
    }
  }

  useEffect(() => {
    if (!qparams.token) {
      setIsDataFetch(true)
      fetchWizardData('', serviceProviderDetails.defaultCountryCode)
      setTimeout(() => {
        localStorage.removeItem('widgetTheme')
      }, 2000)
    }
  }, [])

  useEffect(() => {
    if (!qparams.token && !isInitialLoad) {
      serviceProviderDetails.paymentMethod = []
      serviceProviderDetails.countries.BUY = []
      serviceProviderDetails.countries.SELL = []
      serviceProviderDetails.fiatCurrency = []
      serviceProviderDetails.cryptoCurrency = []
      setServiceProviderDetails({...serviceProviderDetails})
      fetchWizardData(publicKey, userSelectedCountry || serviceProviderDetails.defaultCountryCode, false)
    }
  }, [buySellFlow, isInitialLoad])

  useEffect(() => {
    if (countrySelected && !qparams.token) {
      setCountrySelected(false)
      fetchWizardData(publicKey, userSelectedCountry, true, true)
    }
  }, [countrySelected])

  const fetchWizardData = async (accountId, defaultSelectedCountryCode, fetchData = false, isForceFetch = false) => {
    let defaultCountryCode = defaultSelectedCountryCode
    if (defaultCountryCode) {
      let isValid = currencyByCountry[defaultCountryCode]
      if (!isValid && urlParams?.countryCodeLocked) {
        isValid = currencyByCountry[urlParams?.countryCodeLocked]
        if (!isValid) {
          dispatch(setCountryCodeLocked(false))
        }
        defaultCountryCode = urlParams?.countryCodeLocked
      }
      if (!isValid && urlParams?.countryCode) {
        isValid = currencyByCountry[urlParams?.countryCode]
        defaultCountryCode = urlParams?.countryCode
      }
      if (!isValid && countryCode) {
        isValid = currencyByCountry[countryCode]
        defaultCountryCode = countryCode
      }
      if (!isValid && countryCodeByTimezone) {
        isValid = currencyByCountry[countryCodeByTimezone]
        defaultCountryCode = countryCodeByTimezone
      }
      if (!isValid) {
        defaultCountryCode = 'US'
      }
      dispatch(setCountryCode(defaultCountryCode))
    }
    const category = buySellFlow === 'BUY' ? 'CRYPTO_ONRAMP' : 'CRYPTO_OFFRAMP'
    history.replaceState(null, 'Clean')
    const countryParam = { categories: category, fetchData: fetchData, countryList: buySellFlow === 'BUY' ? serviceProviderDetails.countries.BUY : serviceProviderDetails.countries.SELL }
    countryParam.accountFilter = true
    countryParam.accountCountry = accountCountry
    countryParam.isForceFetch = isForceFetch

    const commonFilter = {categories: category, countries: defaultCountryCode}
    commonFilter.accountFilter = true
    const [serviceProviderCryptoLimit, serviceProviderBuyCountry, serviceProviderPaymentMethod, serviceProviderFiat, serviceProviderCrypto] = await Promise.all([
      fetchServiceProviderCryptoLimit({
        publicKey: publicKey,
        accountId: accountId,
        fetchData: isDataFetch,
        cryptoLimit: serviceProviderDetails.cryptoLimit,
        accountCryptoLimit: accountCryptoLimit,
        isForceFetch: isForceFetch
      }),
      fetchServiceProviderBuyCountry(countryParam),
      fetchPaymentMethod({...commonFilter, accountPaymentMethod: accountPaymentMethod, isForceFetch: isForceFetch, fiatCurrencies: currencyByCountry[defaultCountryCode] }),
      fetchFiatCurrency({...commonFilter, accountFiatCurrency: accountFiatCurrency, isForceFetch: isForceFetch}),
      fetchCryptoCurrency({...commonFilter, accountCryptoCurrency: accountCryptoCurrency, isForceFetch: isForceFetch}),
    ])
    const countryLists = serviceProviderBuyCountry
    let validateCountry = countryLists?.find((country) => country.countryCode === defaultCountryCode)?.countryCode
    if (!validateCountry || _.isEmpty(serviceProviderPaymentMethod) || _.isEmpty(serviceProviderFiat) || _.isEmpty(serviceProviderCrypto)) {
      let regionNames = new Intl.DisplayNames(['en'], {type: 'region'})
      setModalTitle(`${regionNames.of(defaultCountryCode)} is not supported`)
      setModalBody('Please try a different country')
      setIsModalOpen(true)
      validateCountry = countryLists?.find((country) => country.countryCode === 'US')?.countryCode
      const newCountry = validateCountry || countryLists?.[retryAttemp]?.countryCode
      const attempt = retryAttemp + 1
      setRetryAttemp(attempt)
      retryAttemp = attempt
      setUserSelectedCountry(newCountry)
      dispatch(setCountryCode(newCountry))
      userSelectedCountry = newCountry
      setShowWarningIcon(false)
      dispatch(setCountryCodeLocked(false))
      fetchWizardData(publicKey, newCountry, false, true)
      return
    }
    setRetryAttemp(0)
    if (isRedirect && countryCode) {
      setUserSelectedCountry(countryCode)
      userSelectedCountry = countryCode
      dispatch(setCountryCodeLocked(redirectLockCountry))
    }
    if (!_.isEmpty(serviceProviderCryptoLimit)) {
      dispatch(setAccountCryptoLimit(serviceProviderCryptoLimit))
    }
    if (!_.isEmpty(serviceProviderBuyCountry)) {
      dispatch(setAccountCountry(serviceProviderBuyCountry))
    }
    if (!_.isEmpty(serviceProviderPaymentMethod)) {
      dispatch(setAccountPaymentMethod(serviceProviderPaymentMethod))
    }
    if (!_.isEmpty(serviceProviderFiat)) {
      dispatch(setAccountFiatCurrency(JSON.stringify(serviceProviderFiat)))
    }
    if (!_.isEmpty(serviceProviderCrypto)) {
      dispatch(setAccountCryptoCurrency(JSON.stringify(serviceProviderCrypto)))
    }
    serviceProviderDetails.cryptoLimit = serviceProviderCryptoLimit
    serviceProviderDetails.paymentMethod = serviceProviderPaymentMethod
    if (buySellFlow === 'BUY') {
      serviceProviderDetails.countries.BUY = serviceProviderBuyCountry
    }
    if (buySellFlow === 'SELL') {
      serviceProviderDetails.countries.SELL = serviceProviderBuyCountry
    }
    serviceProviderDetails.fiatCurrency = serviceProviderFiat?.sort((a, b) => a.currencyCode.localeCompare(b.currencyCode))
    serviceProviderDetails.cryptoCurrency = serviceProviderCrypto
    serviceProviderDetails.isApiDataFetch = true
    setServiceProviderDetails({...serviceProviderDetails})
    const countryList = serviceProviderDetails.countries?.[buySellFlow]
    // We're fine with instantiating the country based on the timezone
    if (countryCode || countryCodeByTimezone) {
      const defaultCountry = userSelectedCountry || filterCountries({urlParams, countryList, countryCode, countryCodeByTimezone})
      setUserSelectedCountry(defaultCountry)
      dispatch(setCountryCode(defaultCountry))
    }
  }

  const showBuySellToggle = !qparams.publicKey ? true : theme.onrampEnabled && theme.offrampEnabled

  return (
    <>
      {
        isModalOpen &&
        <ErrorModal visible footer={null} maskClosable={false} closeIcon={false}>
          <ModalContent>
            {showWarningIcon && <WarningImg component={WarningIcon} />}
            <ModalTitle>{modalTitle}</ModalTitle>
            <ModalBody>{modalBody}</ModalBody>
            <ContinueButton
              type="primary"
              onClick={(e) => {
                setIsModalOpen(false)
                setModalTitle('')
                setModalBody('')
                setShowWarningIcon(true)
              }}
            >
              Close
            </ContinueButton>
          </ModalContent>
        </ErrorModal>
      }
      { currentStep === 'wizardRedirect' && <WizardRedirect /> }
      {
        currentStep !== 'wizardRedirect' &&
        <WizardPageWrapper
          showCountrySelection={true}
          userSelectedCountry={userSelectedCountry}
          isFetching={hasFetchedWidgetToken}
          accountPreferences={widgetTokenResult}
          showBuySellToggle={showBuySellToggle}
          wizardDrawerRef={wizardDrawerRef}
          countries={serviceProviderDetails.countries?.[buySellFlow]}
          buySellFlow={buySellFlow}
          onCountryChange={(e) => {
            setCountrySelected(true)
            setUserSelectedCountry(e)
            setIsWalletAddressEntered(false)
            setIsCountryChange(true)
          }}
          onFlowChange={(e) => {
            setUserSelectedCountry('')
            setIsInitialLoad(false)
            setTransactionFlow(e)
            setIsWalletAddressEntered(false)
            setIsCountryChange(false)
            setLastCryptoCurrency({})
          }}
          isModalOpen={isModalOpen}
          sellServicePrvider={serviceProviderDetails.serviceProvider.SELL}
        >
          <>
            { currentStep === 'wizard' &&
              <>
                <WizardSourceWrapper
                  countries={serviceProviderDetails.countries?.[buySellFlow]}
                  accountCryptoLimit={serviceProviderDetails.cryptoLimit}
                  accountFiatCurrency={serviceProviderDetails.fiatCurrency}
                  accountCryptoCurrency={serviceProviderDetails.cryptoCurrency}
                  transactionFlow={transactionFlow}
                  isInitialLoad={isInitialLoad}
                  sourceErrorMsg={(e) => setSourceErrorMsg(e)}
                  sourceErrorState={(e) => setIsSourceAmountValid(e)}
                  isWalletAddressEntered={(e) => setIsWalletAddressEntered(e)}
                  lastCryptoCurrency={isCountryChange ? lastCryptoCurrency : {}}
                  lastCryptoCurrencyState={(e) => {
                    setLastCryptoCurrency(e)
                  }}
                />
                <WizardDestinationWrapper
                  serviceProvider={serviceProviderDetails.serviceProvider?.[buySellFlow]}
                  accountFiatCurrency={serviceProviderDetails.fiatCurrency}
                  accountCryptoCurrency={serviceProviderDetails.cryptoCurrency}
                  transactionFlow={transactionFlow}
                  isInitialLoad={isInitialLoad}
                  sourceErrorMsg={sourceErrorMsg}
                  topServiceProviderList={topServiceProviderList}
                  isWalletAddressEntered={isWalletAddressEntered}
                  resetWalletAddressEntered={(e) => setIsWalletAddressEntered(e)}
                  onModalError={(e) => {
                    setModalTitle('Quotes currently unavailable')
                    setModalBody('Please try a different combination')
                    setIsModalOpen(true)
                  }}
                  lastCryptoCurrency={isCountryChange ? lastCryptoCurrency : {}}
                  lastCryptoCurrencyState={(e) => {
                    setLastCryptoCurrency(e)
                  }}
                  isCountryChange={isCountryChange}
                  userWalletAddress={userWalletAddress}
                />
                {
                  transactionFlow === 'BUY' &&
                  <>
                    <WizardWalletWrapper
                      isWalletAddressEntered={(e) => setIsWalletAddressEntered(e)}
                      userWalletAddress={(e) => setUserWalletAddress(e)}
                    />
                  </>
                }
                <WizardPaymentMethodWrapper
                  accountPaymentMethod={serviceProviderDetails.paymentMethod}
                  transactionFlow={transactionFlow}
                  isInitialLoad={isInitialLoad}
                  isWalletAddressEntered={(e) => setIsWalletAddressEntered(e)}
                  lastPaymentMethod={isCountryChange ? lastPaymentMethod : {}}
                  lastPaymentMethodState={(e) => {
                    setLastPaymentMethod(e)
                  }}
                  apiDataFetch={serviceProviderDetails.isApiDataFetch}
                />
                <WizardContinueWrapper
                  serviceProviderDetails={serviceProviderDetails.serviceProvider?.[buySellFlow]}
                  isSourceAmountValid={isSourceAmountValid}
                  resetWalletAddressEntered={(e) => setIsWalletAddressEntered(e)}
                  onModalError={(e) => {
                    setModalTitle(`${buySellFlow === 'BUY' ? 'Onramp' : 'Offramp'} currently unavailable`)
                    setModalBody(`Please try a different combination or ${buySellFlow === 'BUY' ? 'onramp' : 'offramp'}`)
                    setIsModalOpen(true)
                  }}
                />
              </>
            }
            { currentStep === null &&
              <LoadingBlock />
            }
          </>
        </WizardPageWrapper>
      }
    </>
  )
}

export default Wizard
