import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createLink } from '@meshconnect/web-link-sdk'
import { usePostSessionEvent } from 'hooks/api'
import emit from 'utils/events/v2022-10-24'

const MeshProvider = () => {
  const sessionData = useSelector((state) => state.app.sessionData)
  const [ isOpened, setIsOpened ] = useState(false)
  const [ meshEvent, setMeshEvent ] = useState('')
  const [ apiResponse, setApiResponse ] = useState({})
  const [ onExitMesh, setOnExitMesh ] = useState(false)
  const { mutateAsync: postSessionEvent } = usePostSessionEvent()

  const postSession = async (payload, event) => {
    const result = await postSessionEvent(payload)
    if (result?.status < 300 && event === 'transferCompleted') {
      setApiResponse(result)
    }
  }

  const frontConnection = createLink({
    clientId: sessionData?.sessionData?.serviceProviderFrontEndDetails?.clientId,
    onEvent: async (ev) => {
      emit(ev.type, { details: ev.type })
      if (ev.type === 'integrationConnected' || ev.type === 'transferPreviewed' || ev.type === 'transferCompleted') {
        setMeshEvent(ev.type)
        const payload = {
          serviceProviderEventId: ev.type,
          serviceProviderEventType: ev.type,
          serviceProvider: sessionData?.sessionData?.serviceProvider,
          serviceProviderEventPayload: ev.payload
        }
        postSession(payload, ev.type)
      }
    },
    onExit: async () => {
      emit('onExit')
      setOnExitMesh(true)
    }
  })

  const frontConnectionByPass = createLink({
    clientId: sessionData?.sessionData?.serviceProviderFrontEndDetails?.clientId,
    onEvent: async (ev) => {
      emit(ev.type, { details: ev.type })
      if (ev.type === 'integrationConnected' || ev.type === 'transferPreviewed' || ev.type === 'transferCompleted') {
        setMeshEvent(ev.type)
        const payload = {
          serviceProviderEventId: ev.type,
          serviceProviderEventType: ev.type,
          serviceProvider: sessionData?.sessionData?.serviceProvider,
          serviceProviderEventPayload: ev.payload
        }
        postSession(payload, ev.type)
      }
    },
    onExit: async () => {
      emit('onExit')
      setOnExitMesh(true)
    },
    accessTokens: [{
      accessToken: sessionData?.sessionData?.serviceProviderFrontEndDetails?.authenticationBypassDetails?.accessToken,
      brokerType: sessionData?.sessionData?.serviceProviderFrontEndDetails?.authenticationBypassDetails?.broker,
    }]
  })

  useEffect(() => {
    if (apiResponse?.status < 300 && meshEvent === 'transferCompleted' && onExitMesh && sessionData?.sessionData?.redirectUrl !== '') {
      window.location.href = sessionData?.sessionData?.redirectUrl
    }
  }, [meshEvent, apiResponse, onExitMesh])


  useEffect(() => {
    if (sessionData?.sessionData?.serviceProviderUrl && !isOpened) {
      setIsOpened(true)
      if (sessionData?.sessionData?.serviceProviderFrontEndDetails?.authenticationBypassDetails?.accessToken) {
        frontConnectionByPass?.openLink(sessionData?.sessionData?.serviceProviderUrl)
      } else {
        frontConnection?.openLink(sessionData?.sessionData?.serviceProviderUrl)
      }
    }
  }, [frontConnection])

  return (
    <></>
  )
}

export default MeshProvider
