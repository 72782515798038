import React, { useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { useSelector } from 'react-redux'

import LogoPageWrapper from 'components/ui/logo-page-wrapper'
import Loader from 'components/ui/loader'

import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk'

import { useGetQuotes } from 'hooks/api'

import { isSafari } from 'utils/constants'

const RampContainer = styled.div.attrs({
  id: 'ramp-container'
})`
  width: ${({ vw }) => Math.min(Math.max(vw, 320), 400)}px;
  height: 630px;
  min-height: 630px;
  border-radius: ${({ vw }) => vw > 400 ? 10 : 0}px;
  overflow: hidden;
`

const MobileWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SubContext = styled.div`
  font-size: 15px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.foregroundSecondary};
`

const RampProvider = ({ variant }) => {
  const sessionData = useSelector((state) => state.app.sessionData)
  const account = useSelector((state) => state.account.current)
  const theme = useTheme()

  const { isFetching: isFetchingQuotes, data: quoteData } = useGetQuotes({
    sourceAmount: sessionData.sessionData.sourceAmount,
    sourceCurrencyCode: sessionData.sessionData.sourceCurrencyCode,
    countryCode: sessionData.sessionData.countryCode,
    destinationCurrencyCode: sessionData.sessionData.destinationCurrencyCode,
    paymentMethodType: sessionData.sessionData.paymentMethodType,
    serviceProviders: ['RAMP'],
  }, sessionData.sessionData.sessionType === 'SELL')

  let imageUrl = theme.accountLogoUrl
  if (!imageUrl) {
    imageUrl = 'https://rampnetwork.github.io/assets/misc/test-logo.png'
  } else if (imageUrl.indexOf(/https?:\/\//) === -1) {
    imageUrl = window.location.origin + imageUrl
  }

  const rampDomain = sessionData?.sessionData?.serviceProviderUrl || process.env.REACT_APP_RAMP_URL || (sessionData?.sessionData?.serviceProviderEnvironment === 'SANDBOX' ? 'https://app.demo.ramp.network' : 'https://buy.ramp.network')
  const fullRedirectLink = `${rampDomain}?hostAppName=${account.name}&hostApiKey=${sessionData?.sessionData?.serviceProviderPublicApiKey}&swapAsset=${sessionData?.sessionData?.serviceProviderDestinationCurrencyCode}&fiatCurrency=${sessionData?.sessionData?.sourceCurrencyCode}&selectedCountryCode=${sessionData?.sessionData?.countryCode}&fiatValue=${sessionData?.sessionData?.sourceAmount}&userAddress=${sessionData?.sessionData?.walletAddress}`

  if(sessionData?.sessionData?.paymentMethodType === 'APPLE_PAY' && isSafari) {
    window.location.href = fullRedirectLink
  }

  const chainAssetName = `${sessionData?.sessionData?.cryptoChain}_${sessionData?.sessionData?.sessionType === 'BUY' ? sessionData?.sessionData?.serviceProviderDestinationCurrencyCode : sessionData?.sessionData?.serviceProviderSourceCurrencyCode}`.toUpperCase()

  useEffect(() => {
    // Don't start Ramp widget until we have a fiatValue, which should be immediate if BUY
    let fiatValue
    if (sessionData.sessionData.sessionType === 'BUY') {
      fiatValue = sessionData.sessionData.sourceAmount
    } else if (quoteData) {
      fiatValue = quoteData?.quotes?.[0]?.destinationAmount?.toFixed(2)
    }

    if (!fiatValue) {
      return
    }

    new RampInstantSDK({
      hostAppName: account.name,
      hostLogoUrl: imageUrl,
      hostApiKey: sessionData?.sessionData?.serviceProviderPublicApiKey,
      variant: 'embedded-mobile',
      containerNode: document.getElementById('ramp-container'),
      url: rampDomain,
      useSendCryptoCallback: sessionData?.sessionData?.sessionType === 'SELL',
      [sessionData?.sessionData?.sessionType === 'SELL' ? 'offrampWebhookV3Url' : 'webhookStatusUrl']: `${process.env.REACT_APP_BASE_URL_PATH}/webhooks/ramp/transactions/${sessionData?.id}`,
      [sessionData?.sessionData?.sessionType === 'SELL' ? 'offrampAsset' : (sessionData?.sessionData?.lockFields?.includes('destinationCurrencyCode') ? 'swapAsset' : 'defaultAsset')]: chainAssetName,
      enabledFlows: sessionData?.sessionData?.sessionType === 'BUY' ? ['ONRAMP'] : ['OFFRAMP'],
      defaultFlow: sessionData?.sessionData?.sessionType === 'BUY' ? 'ONRAMP' : 'OFFRAMP',
      fiatCurrency: sessionData?.sessionData?.sessionType === 'BUY' ? sessionData?.sessionData?.sourceCurrencyCode : sessionData?.sessionData?.destinationCurrencyCode,
      fiatValue: fiatValue,
      selectedCountryCode: sessionData?.sessionData?.countryCode,
      userAddress: sessionData?.sessionData?.walletAddress,
    })
    .on('*', (event) => {
      switch (event.type) {
        case 'WIDGET_CLOSE':
          return window.location.href = account.options.returnUrl
        case 'WIDGET_CLOSE_REQUEST_CONFIRMED':
          return window.location.href = account.options.returnUrl
        default:
          break
      }
    })
    .show()
  }, [quoteData, sessionData])

  return isFetchingQuotes ? <Loader /> : <div />
}

const RampProviderWrapper = () => {
  const account = useSelector((state) => state.account.current)
  const vw = Math.max(document?.documentElement?.clientWidth || 0, window?.innerWidth || 0)

  const Wrapper = vw < 450 ? MobileWrapper : LogoPageWrapper

  return (
    <Wrapper subtext={false} contained={false}>
      <RampContainer vw={vw}>
        <RampProvider />
      </RampContainer>
      {vw < 915 && <SubContext>Made with care by Meld{account.styling.global.footerPostscript}</SubContext>}
    </Wrapper>
  )
}

export default RampProviderWrapper
