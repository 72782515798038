import React, { useEffect, useState } from 'react'
import { CardInput } from 'components/ui/input'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { isWalletAddressValid } from 'utils/walletAddressValidator'
import { Col, Input, Row } from 'antd'
import { setIsWalletAddressError, setWalletAddress, setWalletTag } from 'store/slices/wizard'
import { walletTagCurrencies } from 'utils/constants'
import { selectTheme } from 'store/slices/theme'

const WalletWrapper = styled.div`
  margin: 10px 0px 10px 0px;
`

const Container = styled.div`
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 8px;
  padding: ${({ padding }) => padding};
  background: ${({ theme }) => theme.colors.walletBackground};
  height: 60px;
`

const Error = styled.div`
  color: #ff2222;
  font-size: 12px;
  height: 15px;
`


const CardInputs = styled.div`
  // margin: 10px 0;
`

const Label = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.labelColor};
`

const LabelValue = styled.span`
  color: ${({ theme }) => theme.colors.labelColor} !important;
`

const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -8px;

  div {
    flex: 1 1;
  }
`

const SearchField = styled(Input)`
  height: 34px;
  width: ${({ width }) => width === 1 ? 90 : (width === 2 ? 85 : 100)}%;
  color: ${({ theme }) => theme.colors.drawerSearchInput} !important;
  padding: 0px !important;

  .ant-input{
    font-size: 14px !important;
    color: ${({ theme }) => theme.colors.drawerSearchInput} !important;
  }
`

const FormRow = styled(Row)`
  box-sizing: border-box;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  vertical-align: top;
`
const InputLabel = styled.label`
  font-size: 14px;
  /* margin-bottom: 10px; */
  display: block;
  color :${({ theme }) => theme.colors.labelColorSecondary};
`

const WizardWalletWrapper = ({ isWalletAddressEntered, userWalletAddress }) => {
  const dispatch = useDispatch()
  const theme = useSelector(selectTheme)
  const { buySellFlow, destinationCurrencyCode, sourceCurrencyCode, urlParams, walletAddress, walletTag, isRedirect } = useSelector((state) => state.wizard)
  const [isError, setIsError] = useState(false)
  const [selectedWallet, setSelectedWallet] = useState('')
  const [selectedTag, setSelectedTag] = useState('')

  const validateWalletAddress = (walletAddr, status) => {
    setSelectedWallet(walletAddr)
    isWalletAddressEntered((urlParams?.walletAddress || urlParams?.walletAddressLocked || isRedirect) ? false : (status === 'false' ? false : true))
    if (walletAddr === '') {
      userWalletAddress(walletAddr)
      setIsError(false)
      dispatch(setWalletAddress(''))
      // dispatch(setIsWalletAddressError(true))
    } else {
      if (!isWalletAddressValid(walletAddr, (buySellFlow === 'BUY' ? destinationCurrencyCode : sourceCurrencyCode))) {
        setIsError(true)
        dispatch(setIsWalletAddressError(true))
      } else {
        userWalletAddress(walletAddr)
        setIsError(false)
        dispatch(setWalletAddress(walletAddr))
        dispatch(setIsWalletAddressError(false))
      }
    }
  }

  useEffect(() => {
    setSelectedWallet(walletAddress || urlParams?.walletAddress || urlParams?.walletAddressLocked || '')
    if (destinationCurrencyCode && urlParams?.walletAddressLocked || urlParams?.walletAddress) {
      validateWalletAddress(urlParams?.walletAddressLocked || urlParams?.walletAddress, 'false')
    }
  }, [urlParams, destinationCurrencyCode, sourceCurrencyCode, walletAddress])

  useEffect(() => {
    if (destinationCurrencyCode && (selectedWallet || walletAddress)) {
      validateWalletAddress(selectedWallet || walletAddress, 'false')
    }
  }, [destinationCurrencyCode])

  return (
    <>
      <InputLabel>Wallet Address</InputLabel>
      <WalletWrapper>
        <FormRow gutter={12}>
          {
            buySellFlow === 'BUY' &&
            <Col span={walletTagCurrencies?.includes(sourceCurrencyCode) || walletTagCurrencies?.includes(destinationCurrencyCode) ? 12 : 24}>
              <Container padding={!urlParams?.walletAddressLocked ? '18px 10px 3px 10px' : '10px'} borderColor={selectedWallet === '' ? '#ff2222' : theme.colors.cardBorder}>
                {
                  !urlParams?.walletAddressLocked ?
                    <CardInput walletAddress={selectedWallet}>
                      <input
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={urlParams?.walletAddress ? 'on' : false}
                        type="text"
                        required="required"
                        onChange={(e) => {
                          validateWalletAddress(e.target.value, 'true')
                        }}
                        value={selectedWallet}
                      />
                      <LabelValue>Enter wallet address to proceed</LabelValue>
                    </CardInput>
                    :
                    <CardInputs>
                      <Row><Col style={{ marginTop: '-5px' }}><Label>Enter wallet address to proceed</Label></Col></Row>
                      <AmountWrapper>
                        <SearchField
                          type='text'
                          bordered={false}
                          placeholder={''}
                          disabled={urlParams?.walletAddressLocked ? true : false}
                          onChange={(e) => {
                            validateWalletAddress(e.target.value)
                          }}
                          value={selectedWallet}
                        />
                      </AmountWrapper>
                    </CardInputs>
                }
              </Container>
              <Error>{isError && 'Invalid wallet address'}</Error>
            </Col>
          }
          {
            (walletTagCurrencies?.includes(sourceCurrencyCode) || walletTagCurrencies?.includes(destinationCurrencyCode)) &&
            <Col span={buySellFlow === 'SELL' ? 24 : 12}>
              <Container padding={'18px 10px 3px 10px'}>
                <CardInput>
                  <input
                    type="text"
                    required="required"
                    onChange={(e) => {
                      dispatch(setWalletTag(e.target.value))
                      setSelectedTag(e.target.value)
                    }}
                    value={walletTag || selectedTag}
                  />
                  <LabelValue>Wallet Tag</LabelValue>
                </CardInput>
              </Container>
            </Col>
          }
        </FormRow>
      </WalletWrapper>
    </>
  )
}

export default WizardWalletWrapper
