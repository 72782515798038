/* eslint-disable complexity */
import { createSlice } from '@reduxjs/toolkit'

import { getURLParams } from 'utils/data-model'
import { pickDefaultSourceAmount, defaultPublicKey } from 'utils/constants'

// eslint-disable-next-line complexity
const calculateInitialState = (overrides) => {
  let historyState = { ...history.state, ...overrides }
  const urlParams = { ...getURLParams(), ...overrides }
  const wizardRedirect = sessionStorage.getItem('wizardRedirect')
  let sessionData = sessionStorage.getItem('wizardSessionData')
  sessionData = sessionData && JSON.parse(sessionData) || {}

  let wizardAccountSupportedPreferences = sessionStorage.getItem('wizardAccountSupportedPreferences')
  wizardAccountSupportedPreferences = wizardAccountSupportedPreferences && JSON.parse(wizardAccountSupportedPreferences) || {}

  let wizardAccountSupportedData = sessionStorage.getItem('wizardAccountSupportedData')
  wizardAccountSupportedData = wizardAccountSupportedData && JSON.parse(wizardAccountSupportedData) || {}

  if (wizardRedirect && sessionData && sessionData.countryCode) {
    historyState = sessionData
    historyState.accountPublicPreference = wizardAccountSupportedPreferences.accountPublicPreference
    historyState.accountOnRampServiceProviders = wizardAccountSupportedPreferences.accountOnRampServiceProviders
    historyState.accountOffRampServiceProviders = wizardAccountSupportedPreferences.accountOffRampServiceProviders
    historyState.lockCountryCode = wizardAccountSupportedPreferences.lockCountryCode
    historyState.redirectLockCountry = wizardAccountSupportedPreferences.lockCountryCode

    historyState.accountCryptoLimit = wizardAccountSupportedData.accountCryptoLimit
    historyState.accountCountry = wizardAccountSupportedData.accountCountry
    historyState.accountFiatCurrency = wizardAccountSupportedData.accountFiatCurrency
    historyState.accountCryptoCurrency = wizardAccountSupportedData.accountCryptoCurrency
    historyState.accountPaymentMethod = wizardAccountSupportedData.accountPaymentMethod
    historyState.isRedirect = true

    sessionStorage.removeItem('wizardSessionData')
    sessionStorage.removeItem('wizardAccountSupportedPreferences')
    sessionStorage.removeItem('wizardAccountSupportedData')
  }

  // When it comes to setting initial state, we care the most about the history state (as it's based on the user's selections),
  // then we default to what was passed in as a URL parameter,
  // then finally we resort to default values
  const transactionLocked = urlParams?.transactionTypeLocked?.toUpperCase() === 'BUY' || urlParams?.transactionTypeLocked?.toUpperCase() === 'SELL'
  const transactionType = (transactionLocked && urlParams?.transactionTypeLocked?.toUpperCase())  || urlParams?.transactionType?.toUpperCase()

  return {
    // We also make history state and url params available to the rest of the app, for cases where we care if it was URL-based
    historyState,
    urlParams,
    publicKey: urlParams.publicKey ?? defaultPublicKey?.publicKey,
    currentStep: 'wizard',
    buySellFlow: historyState.transactionType || transactionType || 'BUY',
    buySellFlowLocked: transactionLocked,
    countryCode: historyState.countryCode || urlParams.countryCodeLocked || urlParams.countryCode,
    lockCountryCode: historyState.lockCountryCode || !!urlParams.countryCodeLocked,
    redirectLockCountry: historyState.lockCountryCode || false,
    paymentMethod: historyState.paymentMethodType || urlParams.paymentMethodTypeLocked || urlParams.paymentMethodType || {},
    paymentMethodType: historyState.paymentMethodType || urlParams.paymentMethodTypeLocked || urlParams.paymentMethodType,
    lockPaymentMethod: !!urlParams.paymentMethodTypeLocked,
    sourceAmount: historyState.sourceAmount || urlParams.sourceAmountLocked || urlParams.sourceAmount || pickDefaultSourceAmount((transactionType === 'SELL' ? urlParams.sourceCurrencyCodeLock ?? urlParams.sourceCurrencyCode ?? 'BTC' : 'USD')),
    lockSourceAmount: !!urlParams.sourceAmountLocked,
    sourceCurrencyCode: historyState.sourceCurrencyCode || (transactionType === 'BUY' ? 'USD' : undefined),
    lockSourceCurrencyCode: !!urlParams.sourceCurrencyCodeLocked,
    destinationCurrencyCode: historyState.destinationCurrencyCode,
    lockDestinationCurrencyCode: !!urlParams.destinationCurrencyCodeLocked,
    walletAddress: historyState.walletAddress || urlParams.walletAddressLocked || urlParams.walletAddress || '',
    lockWalletAddress: !!urlParams.walletAddressLocked,
    walletTag: historyState.walletTag || urlParams.walletTag || '',
    errorDetails: {},
    wizardDrawerRef: {},
    reinitialize: 0,
    initialLockSourceCurrencyCode: urlParams.sourceCurrencyCodeLocked,
    initialSourceCurrencyCode: urlParams.sourceCurrencyCode,
    initialLockDestinationCurrencyCode: urlParams.destinationCurrencyCodeLocked,
    initialDestinationCurrencyCode: urlParams.destinationCurrencyCode,
    isSellSourceAmountChange: false,
    isSourceAmountError: false,
    isWalletAddressError: false,
    destinationCurrencyAmount: 0,
    exchangeRateAmount: 0,
    initialAmount: 0,
    isWizardLoad: false,
    selectedQuotes: [],
    isCountrySelected: false,
    selectedSource: {},
    selectedDestination: {},
    accountPublicPreference: historyState.accountPublicPreference || {},
    accountOnRampServiceProviders: historyState.accountOnRampServiceProviders || [],
    accountOffRampServiceProviders: historyState.accountOffRampServiceProviders || [],
    accountCryptoLimit: historyState.accountCryptoLimit || [],
    accountCountry: historyState.accountCountry || [],
    accountFiatCurrency: historyState.accountFiatCurrency || '',
    accountCryptoCurrency: historyState.accountCryptoCurrency || '',
    accountPaymentMethod: historyState.accountPaymentMethod || [],
    isRedirect: historyState.isRedirect || false,
  }
}

export const wizardSlice = createSlice({
  name: 'wizard',
  initialState: calculateInitialState(),
  reducers: {
    setPublicKey: (state, action) => {
      state.publicKey = action.payload
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    setBuySellFlow: (state, action) => ({
      ...calculateInitialState(),
      buySellFlow: action.payload,
      reinitialize: state.reinitialize + 1
    }),
    setCountryCode: (state, action) => {
      state.countryCode = action.payload
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload
    },
    setPaymentMethodType: (state, action) => {
      state.paymentMethodType = action.payload
    },
    setLockPaymentMethod: (state, action) => {
      state.lockPaymentMethod = action.payload
    },
    setSourceAmount: (state, action) => {
      state.sourceAmount = action.payload
    },
    setSourceCurrencyCode: (state, action) => {
      state.sourceCurrencyCode = action.payload
    },
    setDestinationCurrencyCode: (state, action) => {
      state.destinationCurrencyCode = action.payload
    },
    setWalletAddress: (state, action) => {
      state.walletAddress = action.payload
    },
    setWalletTag: (state, action) => {
      state.walletTag = action.payload
    },
    setErrorDetails: (state, action) => {
      state.errorDetails = {
        message: action.payload.message,
        backToStep: action.payload.backToStep,
      }
      state.currentStep = 'wizardError'
    },
    setWizardDrawerRef: (state, action) => {
      state.wizardDrawerRef.current = action.payload
    },
    setSourceCurrencyCodeLocked: (state, action) => {
      state.lockSourceCurrencyCode = action.payload
    },
    setDestinationCurrencyCodeLocked: (state, action) => {
      state.lockDestinationCurrencyCode = action.payload
    },
    setCountryCodeLocked: (state, action) => {
      state.lockCountryCode = action.payload
    },
    setSourceAmountLocked: (state, action) => {
      state.lockSourceAmount = action.payload
    },
    setIsSellSourceAmountChange: (state, action) => {
      state.isSellSourceAmountChange = action.payload
    },
    setIsSourceAmountError: (state, action) => {
      state.isSourceAmountError = action.payload
    },
    setIsWalletAddressError: (state, action) => {
      state.isWalletAddressError = action.payload
    },
    setDestinationCurrencyAmount: (state, action) => {
      state.destinationCurrencyAmount = action.payload
    },
    setExchangeRateAmount: (state, action) => {
      state.exchangeRateAmount = action.payload
    },
    setInitialAmount: (state, action) => {
      state.initialAmount = action.payload
    },
    setWizardLoad: (state, action) => {
      state.isWizardLoad = action.payload
    },
    setSelectedQuotes: (state, action) => {
      state.selectedQuotes = action.payload
    },
    setIsCountrySelected: (state, action) => {
      state.isCountrySelected = action.payload
    },
    setSelectedSource: (state, action) => {
      state.selectedSource = action.payload
    },
    setSelectedDestination: (state, action) => {
      state.selectedDestination = action.payload
    },
    setAccountPublicPreference: (state, action) => {
      state.accountPublicPreference = action.payload
    },
    setAccountOnRampServiceProviders: (state, action) => {
      state.accountOnRampServiceProviders = action.payload
    },
    setAccountOffRampServiceProviders: (state, action) => {
      state.accountOffRampServiceProviders = action.payload
    },
    setAccountCryptoLimit: (state, action) => {
      state.accountCryptoLimit = action.payload
    },
    setAccountCountry: (state, action) => {
      state.accountCountry = action.payload
    },
    setAccountFiatCurrency: (state, action) => {
      state.accountFiatCurrency = action.payload
    },
    setAccountCryptoCurrency: (state, action) => {
      state.accountCryptoCurrency = action.payload
    },
    setAccountPaymentMethod: (state, action) => {
      state.accountPaymentMethod = action.payload
    },
  },
})

export const {
  setPublicKey,
  setCurrentStep,
  setBuySellFlow,
  setCountryCode,
  setPaymentMethod,
  setPaymentMethodType,
  setLockPaymentMethod,
  setSourceAmount,
  setSourceCurrencyCode,
  setDestinationCurrencyCode,
  setWalletAddress,
  setWalletTag,
  setErrorDetails,
  setWizardDrawerRef,
  setSourceCurrencyCodeLocked,
  setDestinationCurrencyCodeLocked,
  setCountryCodeLocked,
  setSourceAmountLocked,
  setIsSellSourceAmountChange,
  setIsSourceAmountError,
  setIsWalletAddressError,
  setDestinationCurrencyAmount,
  setExchangeRateAmount,
  setInitialAmount,
  setWizardLoad,
  setSelectedQuotes,
  setIsCountrySelected,
  setSelectedSource,
  setSelectedDestination,
  setAccountPublicPreference,
  setAccountOnRampServiceProviders,
  setAccountOffRampServiceProviders,
  setAccountCryptoLimit,
  setAccountCountry,
  setAccountFiatCurrency,
  setAccountCryptoCurrency,
  setAccountPaymentMethod,
} = wizardSlice.actions

export default wizardSlice.reducer
