import React from 'react'
import { useSelector } from 'react-redux'

import LogoPageWrapper from 'components/ui/logo-page-wrapper'

import { isSafari } from 'utils/constants'

const OnrampMoneyProvider = () => {
  const sessionData = useSelector((state) => state.app.sessionData)

  if (sessionData?.sessionData?.paymentMethodType === 'APPLE_PAY' && isSafari) {
    window.location.href = sessionData?.sessionData?.serviceProviderUrl
  }

  return (
    <LogoPageWrapper>
      <iframe height="630" title="Onramp.money Widget"
        src={sessionData?.sessionData?.serviceProviderUrl}
        frameBorder="no"
        allowtransparency="true"
        allowFullScreen=""
        allow="payment"
        style={{ display: 'block', width: '100%', maxHeight: '100%', maxWidth: '500px' }}
      />
    </LogoPageWrapper>
  )
}

export default OnrampMoneyProvider
